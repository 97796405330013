import React from "react";
import { IconFileCode, IconTrashFilled } from "@tabler/icons-react";
import { Button, CheckIcon, Kbd } from "@mantine/core";
import useCliboard from "../../hooks/useCliboard";

export default function CopyPoly({ setState, state }) {
  const { seePaste } = useCliboard();
  seePaste(setState, state);
  return (
    <div className="ctrlV">
      <div className="icon">
        <IconFileCode size={48} strokeWidth={1} />
      </div>
      <div className="texts">
        <h2>Ctrl + V ni bosing</h2>
        <p>
          Figmadagi framedan nusxa oling va ushbu maydonga cursorni olib kelib{" "}
          <Kbd>CTRL</Kbd> +<Kbd>V</Kbd> tugmalarini bosing
        </p>
      </div>
      {state && (
        <div className="check">
          <p>
            Yuklandi <CheckIcon color="green" />
          </p>
          <Button
            onClick={() => setState(undefined)}
            variant="filled"
            color="red"
          >
            <IconTrashFilled size="1rem" /> Qayta yuklash
          </Button>
        </div>
      )}
    </div>
  );
}
