import { Badge, Button, Container, Grid } from "@mantine/core";
// import { Badge, Button, Container, Grid, Image } from "@mantine/core";
import React, { useEffect, useState } from "react";
import "./style.css";
import Navbar from "./header";
// import useCliboard from "../hooks/useCliboard";
// import { ReactComponent as FIcon } from "../icons/figma.svg";
import { get, getDatabase, ref } from "firebase/database";
import app from "../hooks/firebaseConfig";
export default function MainComponent() {
  const [data, setData] = useState([]);
  const [code, setCode] = useState("Kelmadi xali");
  // const { copyData } = useCliboard();
  console.log(data);
  async function fetchdata() {
    const db = getDatabase(app);
    const dbRef = ref(db, "framelar/v1");
    const snapshoot = await get(dbRef);
    if (snapshoot.exists()) {
      setData(Object.values(snapshoot.val()));
    } else {
      console.log("noData");
    }
  }
  useEffect(() => {
    fetchdata();
  }, []);

  // useEffect(() => {
  //   // Detect feature support via OTPCredential availability
  //   if ("OTPCredential" in window) {
  //     window.addEventListener("DOMContentLoaded", (e) => {
  //       const ac = new AbortController();
  //       // const input = document.querySelector(
  //       //   'input[autocomplete="one-time-code"]'
  //       // );
  //       // if (!input) return;
  //       // // Set up an AbortController to use with the OTP request
  //       // const form = input.closest("form");
  //       // if (form) {
  //       //   // Abort the OTP request if the user attempts to submit the form manually
  //       //   form.addEventListener("submit", (e) => {
  //       //     ac.abort();
  //       //   });
  //       // }
  //       // Request the OTP via get()
  //       navigator.credentials
  //         .get({
  //           otp: { transport: ["sms"] },
  //           signal: ac.signal,
  //         })
  //         .then((otp) => {
  //           console.log(otp, "otp");
  //           setCode(otp.code);
  //           // When the OTP is received by the app client, enter it into the form
  //           // input and submit the form automatically
  //           // input.value = otp.code;
  //           // if (form) form.submit();
  //         })
  //         .catch((err) => {
  //           console.error(err);
  //         });
  //     });
  //   }
  // }, []);

  function clickOtp() {
    // alert("clickOtp");
    if ("OTPCredential" in window) {
      alert(`"OTPCredential" in window`);
      // window.addEventListener("DOMContentLoaded", (e) => {
      const ac = new AbortController();
      navigator.credentials
        .get({
          otp: { transport: ["sms"] },
          signal: ac.signal,
        })
        .then((otp) => {
          alert(otp + " : otp");
          setCode(otp.code);
          ac.abort();
        })
        .catch((err) => {
          ac.abort();
          alert(err + " : err");
        });
      // });
    }
  }
  const [otp, setOtp] = useState("");
  useEffect(() => {
    let ac = new AbortController();
    setTimeout(() => {
      // abort after 10 minutes
      ac.abort();
    }, 10 * 60 * 1000);
    navigator.credentials
      .get({
        otp: { transport: ["sms"] },
        signal: ac.signal,
      })
      .then((otp) => {
        setOtp(otp.code);
        //console.log("your otp code is", otp.code);
      })
      .catch((err) => {
        console.error(err);
        alert("Error: " + err);
      });
  });
  return (
    <Container size={"lg"}>
      <Grid gutter={24}>
        <Grid.Col span={12}>
          <Navbar fetchdata={fetchdata} />
        </Grid.Col>

        <Grid.Col span={12}>
          <Button variant="default" onClick={() => clickOtp()}>
            Kodni olish
          </Button>{" "}
          <br /> Kelgan Kod: <Badge variant="defualt">{code}</Badge>
          <br /> OTP Kod: <Badge variant="defualt">{otp ?? "---"}</Badge>
        </Grid.Col>

        {/* <Grid.Col span={12}>
          <Grid>
            {data?.length > 0 ? (
              <>
                {data?.map((a, i) => (
                  <Grid.Col key={i} span={4}>
                    <div className="imageCard">
                      <Image
                        src={a?.img_link}
                        width={"100%"}
                        radius={8}
                        height={250}
                        // fit="contain"
                      />
                      <button onClick={() => copyData(a?.data)}>
                        <span>
                          <FIcon />
                        </span>
                        Figma framedan nusxa olish
                      </button>

                      <p>Yaratildi: {a?.date}</p>
                    </div>
                  </Grid.Col>
                ))}
              </>
            ) : (
              <Grid.Col span={12}>
                <p style={{ textAlign: "center" }}>Ma'lumot topilmadi</p>
              </Grid.Col>
            )}
          </Grid>
        </Grid.Col> */}
      </Grid>
    </Container>
  );
}
