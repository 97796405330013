import { MantineProvider } from "@mantine/core";
import "./global.css";
import MainComponent from "./components";
import { Notifications } from "@mantine/notifications";

function App() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <Notifications position="top-right" />
      <MainComponent />
    </MantineProvider>
  );
}

export default App;
