import React from "react";
import ModalButton from "../modal";

export default function Navbar({ fetchdata }) {
  return (
    <div className="navbar">
      <ModalButton fetchdata={fetchdata} />
    </div>
  );
}
