import { notifications } from "@mantine/notifications";

export default function useCliboard() {
  function seePaste(setstate, state) {
    if (state === undefined) {
      document.addEventListener("paste", function (e) {
        var clipboardData = e.clipboardData || window.clipboardData;
        if (
          clipboardData &&
          clipboardData.types &&
          clipboardData.types.indexOf("text/html") > -1
        ) {
          setstate(undefined);
          const htmlData = clipboardData.getData("text/html");
          setstate(htmlData);
          e.preventDefault();
        }
      });
    }
  }

  function copyData(cl) {
    const clipboardItem = new ClipboardItem({
      "text/html": new Blob([cl], { type: "text/html" }),
    });

    navigator.clipboard.write([clipboardItem]).then(
      () => {
        notifications.show({
          title: `Figmaga o'tib CTRL + V ni bosing`,
          message: "Framedan nusxa oldingiz.",
        });
      },
      (err) => {
        console.log(err);
      }
    );
    // });
  }

  return { copyData, seePaste };
}
