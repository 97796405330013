import "./style.css";
import { useRef, useState } from "react";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { IconPhoto, IconTrash } from "@tabler/icons-react";
import { Image, LoadingOverlay } from "@mantine/core";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
import { storage } from "../../hooks/firebaseConfig";
import dayjs from "dayjs";
export default function FileUploade({ file, setFile }) {
  const openRef = useRef(null);
  const [loading, setLoading] = useState(false);

  function imgUpload(file) {
    if (file) {
      setLoading(true);
      const name =
        "Azamov---" + dayjs().format("DD-MM-YYYY--HH-mm-ss---") + file.name;
      const fullNameFile = "images/" + name;
      const imgRef = ref(storage, fullNameFile);

      uploadBytes(imgRef, file)
        .then((data) => {
          getDownloadURL(data?.ref).then((url) => {
            setFile(url);
          });
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  return (
    <div className="dropoImage">
      <LoadingOverlay visible={loading} />
      {file !== undefined ? (
        <div className="imageWrapper">
          <div className="fade">
            <button onClick={() => setFile(undefined)}>
              <IconTrash size={48} strokeWidth={1.5} />
            </button>
          </div>
          <Image src={file} width={"100%"} height={130} radius={8} />
        </div>
      ) : (
        <Dropzone
          multiple={false}
          openRef={openRef}
          onDrop={(files) => {
            imgUpload(files[0]);
          }}
          accept={IMAGE_MIME_TYPE}
        >
          <div className="fileuploade">
            <IconPhoto size="2rem" stroke={1} />
            <div className="wrap-text">
              <h2>Rasm yuklang</h2>
              <p>Hajmi maksmal 2 mb gacha bo'lgan rasm yuklang</p>
            </div>
          </div>
        </Dropzone>
      )}
    </div>
  );
}
