import { Button, Grid, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React, { useEffect, useState } from "react";
import CopyPoly from "./copy";
import app from "../../hooks/firebaseConfig";
import { getDatabase, ref, set, push } from "firebase/database";
import dayjs from "dayjs";
import { notifications } from "@mantine/notifications";
import FileUploade from "../fileuploade";
import axios from "axios";
export default function ModalButton({ fetchdata }) {
  const [IP, setIP] = useState(undefined);
  const [data, setData] = useState(undefined);
  const [file, setFile] = useState(undefined);
  const [opened, { open, close }] = useDisclosure(false);

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
  };

  function create() {
    const db = getDatabase(app);
    const newDocRef = push(ref(db, "framelar/v1"));
    set(newDocRef, {
      date: dayjs().format("DD.MM.YYYY | HH:mm"),
      ip: IP,
      data: data,
      img_link: file,
    })
      .then(() => {
        notifications.show({
          title: `Tabriklaymiz`,
          message: "muvaffaqqiyatli yaratildi.",
        });
        close();
        setData(undefined);
        setData(undefined);
        fetchdata();
      })
      .catch((error) => {
        console.log(error);
        notifications.show({
          title: `Xatolik`,
          message: error.message,
        });
      });
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Button onClick={open} radius={"md"} variant="light">
        Yangi yaratish
      </Button>

      <Modal
        radius={12}
        opened={opened}
        onClose={close}
        title="Yangi Ma'lumot qo'shish"
      >
        <Grid>
          <Grid.Col span={12}>
            <CopyPoly setState={setData} state={data} />
          </Grid.Col>
          <Grid.Col span={12}>
            <FileUploade file={file} setFile={setFile} />
          </Grid.Col>
          <Grid.Col span={12}>
            <Button
              disabled={file === undefined || data === undefined}
              onClick={() => create()}
              w={"100%"}
            >
              Yaratish
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>
    </>
  );
}
